@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  html {
    font-family: "Montserrat", system-ui, sans-serif;
  }
}


body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;
}

.spinner .path {
  stroke: hsl(210, 70%, 75%);
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.tgl {
  display: none;
}

.tgl,
.tgl:after,
.tgl:before,
.tgl *:after,
.tgl *:before,
.tgl + .tgl-btn {
  box-sizing: border-box;
}

.tgl::selection {
  background: none;
}

.tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 10em;
  height: 2em;
  position: relative;
  cursor: pointer;
  user-select: none;
}

.tgl + .tgl-btn:after,
.tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}

.tgl + .tgl-btn:after {
  left: 0;
}

.tgl + .tgl-btn:before {
  display: none;
}

.tgl:checked + .tgl-btn:after {
  left: 50%;
}

.tgl-flip + .tgl-btn {
  padding: 2px;
  transition: all .2s ease;
  font-family: sans-serif;
  perspective: 100px;
}

.tgl-flip + .tgl-btn:after,
.tgl-flip + .tgl-btn:before {
  display: inline-block;
  transition: all .4s ease;
  width: 100%;
  text-align: center;
  position: absolute;
  line-height: 2em;
  font-weight: bold;
  color: #fff;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  border-radius: 4px;
}

.tgl-flip + .tgl-btn:after {
  content: attr(data-tg-on);
  background: #7FC6A6;
  transform: rotateY(-180deg);
}

.tgl-flip + .tgl-btn:before {
  background: #02C66F;
  content: attr(data-tg-off);
}

.tgl-flip + .tgl-btn:active:before {
  transform: rotateY(-20deg);
}

.tgl-flip:checked + .tgl-btn:before {
  transform: rotateY(180deg);
}

.tgl-flip:checked + .tgl-btn:after {
  transform: rotateY(0);
  left: 0;
  background: #007bff;
}

.tgl-flip:checked + .tgl-btn:active:after {
  transform: rotateY(20deg);
}

.separator {
  margin-bottom: 15px;
}

.table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th,
.table-borderless > tfoot > tr > td,
.table-borderless > tfoot > tr > th,
.table-borderless > thead > tr > td,
.table-borderless > thead > tr > th {
    border: none;
}